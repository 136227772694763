<template lang="pug">
.dashboard-wrapper
  b-row
    b-col(cols="12")
      b-card(:title="$t('message.note_title')")
        b-card-text
          | {{ $t('message.improvement_dashboard_note') }}

  b-row
    b-col
      b-card(no-body, class="pt-2 px-2")
        filters(
          :customFiltersShow="true"
          :zoneFilterShow="zone_agency_filter"
          :agencyFilterShow="zone_agency_filter"
          :locationFilterShow="true"
          :dateRangeFilterShow="true"
          :locationDefaultValue="true"
          :supervisorFilterShow="true"
          supervisorFilterPlaceholder="creator_filter"
          @changeFilter="updateFilter"
        )
  
  b-row(v-if="isLoading")
    b-col
      b-card(class="py-5")
        loading(
          :active="true" 
          :is-full-page="false"
          :color="colors.primary"
        )

  div(v-else)
    b-row(v-if="!improvementsTotal")
      b-col
        b-card(class="text-center")
          h5(class="mb-0")
            | {{ $t('message.no_improvements_found') }}

    div(v-else)
      b-row
        b-col(sm="3")
          statistic-card-vertical-total(:statistic="improvementsTotal")
        b-col(sm="3")
          statistic-card-vertical-completed(:statistic="improvementsCompleted")
        b-col(sm="3")
          statistic-card-vertical-pending(:statistic="improvementsPending")

      b-row
        b-col(lg="6")
          bar-chart-location(
            v-if="locationImprovements.labels && locationImprovements.labels.length",
            :locations-data="locationImprovements"
          )
        b-col(lg="6")
          bar-chart-creator(
            v-if="creatorImprovements.labels && creatorImprovements.labels.length",
            :creators-data="creatorImprovements"
          )
      
      b-row  
        b-col(lg="6")
          donut-chart-domain(
            v-if="domainImprovements.labels && domainImprovements.labels.length",
            :domain-data="domainImprovements"
          )

      b-row(v-if="cloudWords.length")
        b-col(cols="12")
          b-card(:title="$t('message.word_cloud_title')")
            b-card-text(v-if="isWordCloudLoading", class="pb-2")
              | {{ $t('message.loading_word_cloud') }} ({{ loadingWordCount }} {{ $t('message.words') }})...
            div(class="wordCloud")
              vue-word-cloud(
                :words="cloudWords"
                :color="setWordCloudColors"
                :spacing="0.5"
                style="position: static"
                font-family="Montserrat"
                @update:progress="setWordCloudLoader"
              )
      
      b-card
        b-table-simple(hover, small, caption-top, responsive)
          b-thead(head-variant="light")
            b-tr
              th {{ $t(`improvement_opp.${inDemo ? 'dueDate' : 'date'}`) }}
              th {{ $t('improvement_opp.created_by') }}
              th {{ $t('improvement_opp.location') }}
              th {{ $t('improvement_opp.domain') }}
              th {{ $t('improvement_opp.improvement') }}
              th {{ $t('improvement_opp.state') }}
              th(v-if="userRole === 'consultant'") {{ $t('improvement_opp.action') }}
            b-tr(v-for="(improvement, index) in improvements", :key="index")
              b-td {{ improvement.date }}
              b-td {{ improvement.created_by }}
              b-td {{ improvement.location }}
              b-td {{ improvement.domain.value }}
              b-td {{ improvement.note }}
              b-td(:class="improvement.completed ? 'text-success' : 'text-danger'") {{ improvement.completedState.value }}
              b-td(v-if="userRole === 'consultant'")
                delete-button(v-b-modal="`modal ${index}`")
                b-modal(
                  :id="`modal ${index}`",
                  centered,
                  no-close-on-backdrop,
                  modal-class="modal-danger"
                  ok-variant="danger"
                  cancel-variant="outline-secondary"
                  :title="$t('message.confirm_action')",
                  :ok-title="$t('message.delete')",
                  :cancel-title="$t('message.cancel')",
                  @ok="deleteImprovement(improvement.id)",
                )
                  | {{ $t('message.confirm_delete_improvement') }}

      b-button(:disabled="!improvements.length")
        json-excel(:data="improvements", :fields="excelFields")
          | {{ $t('download_xlsx') }}
</template>

<script>
import { onMounted, ref, computed } from "@vue/composition-api/dist/vue-composition-api"
import store from "@/store"
import useNotifications from "@/composables/useNotifications"
import { BTable, BTableSimple, BThead, BTr, BTd, BCard, BCardText, BModal, VBModal } from "bootstrap-vue"
import JsonExcel from "vue-json-excel";
import StatisticCardVerticalTotal from '../charts/StatisticCardVerticalTotal.vue'
import StatisticCardVerticalCompleted from '../charts/StatisticCardVerticalCompleted.vue'
import StatisticCardVerticalPending from '../charts/StatisticCardVerticalPending.vue'
import BarChartCreator from '../charts/ChartjsHorizontalBarChartCreator.vue'
import BarChartLocation from '../charts/ChartjsHorizontalBarChartLocation.vue'
import DonutChartDomain from '../charts/ApexDonutChartDomain.vue'
import Filters from "@/views/organization/Filters.vue"
import i18n from '@/libs/i18n'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton'
import useCommonDashboards from '@/views/habit/useCommonDashboards'
import VueWordCloud from 'vuewordcloud'
import { $themeColors } from '@themeConfig'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import useCommon from '@/views/organization/useCommon'
import { colors } from '@/constants'
import endpoints from '@/libs/endpoints'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BTable,
    BTableSimple,
    BThead,
    BTr,
    BTd,
    BCard,
    BCardText,
    BModal,
    JsonExcel,
    StatisticCardVerticalTotal,
    StatisticCardVerticalCompleted,
    StatisticCardVerticalPending,
    BarChartCreator,
    BarChartLocation,
    DonutChartDomain,
    Filters,
    DeleteButton,
    [VueWordCloud.name]: VueWordCloud,
    Loading,
  },
  directives: {
    'b-modal': VBModal,
  },
  setup() {
    const userData = store.state?.userStore?.userData;
    const userRole = userData.role;
    const client_id = userRole !== "admin" ? userData.client.$oid : null;
    const userLocations = userData.locations?.length ? userData.locations : null;
    const { showSuccessMessage, showErrorMessage } = useNotifications();
    const { handleError } = useCommon()
    const { getItemsWithAggregate, ObjectId, updateItem } = realmConnection()
    const improvements = ref([]);
    const improvementsTotal = ref(0)
    const improvementsCompleted = ref(0)
    const improvementsPending = ref(0)
    const creatorImprovements = ref({})
    const locationImprovements = ref({})
    const domainImprovements = ref({})
    const locationFilter = ref(userLocations ? userLocations.map(e => e.value) : []);
    const supervisorFilter = ref([]);
    const zoneFilter = ref([]);
    const agencyFilter = ref([]);
    const cloudWords = ref([])
    const isWordCloudLoading = ref(false)
    const loadingWordCount = ref(0)
    const now = new Date()
    const currentMonth = now.getMonth()
    const currentYear = now.getFullYear()
    const lastDayOfMonthDate = new Date(currentYear, currentMonth + 1, 0)
    const lastDayOfMonthNumber = lastDayOfMonthDate.getDate()
    const dateRangeFilter = ref(`01-${currentMonth + 1}-${currentYear} to ${lastDayOfMonthNumber}-${currentMonth + 1}-${currentYear}`)
    const isLoading = ref(true)
    const excelFields = ref({})
    const { zone_agency_filter, default_language } = JSON.parse(localStorage.getItem('clientData') || '{}')
    const { dateFromObjectId, getDatesFromRange } = useCommonDashboards()
    const inDemo = endpoints.app_id === process.env.VUE_APP_APP_ID_DEMO

    async function listImprovements() {
      isLoading.value = true

      try {
        const initialQuery = {
          client_id: ObjectId(client_id),
          deleted: { $ne: true }
        }

        if (supervisorFilter.value.length > 0) {
          initialQuery.created_by = { $in: supervisorFilter.value.map(e => ObjectId(e)) }
        }

        const finalQuery = {}

        if (locationFilter.value.length > 0) {
          finalQuery['location._id'] = { $in: locationFilter.value }
        }

        if (zoneFilter.value.length > 0) {
          finalQuery['location.zone'] = { $in: zoneFilter.value }
        }

        if (agencyFilter.value.length > 0) {
          finalQuery['location.agency'] = { $in: agencyFilter.value }
        }

        const pipeline = [
          { $match: initialQuery },
          {
            $lookup: {
              from: 'location',
              localField: 'location',
              foreignField: '_id',
              pipeline: [ { $project: { location: 1, zone: 1, agency: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'location'
            }
          },
          { $match: finalQuery },
          {
            $lookup: {
              from: 'worker',
              localField: 'created_by',
              foreignField: '_id',
              pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'created_by'
            }
          },
          {
            $lookup: {
              from: 'improvement',
              localField: 'improvements',
              foreignField: '_id',
              pipeline: [ { $project: { domain: 1, note: 1, deleted: 1 , completed: 1, dueDate: 1, metadata: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ],
              as: 'improvements'
            }
          },
          { $addFields: { _id: { $toString: "$_id" }, location: { $arrayElemAt: ["$location", 0] }, created_by: { $arrayElemAt: ["$created_by", 0] } } },
        ]
      
        const items = await getItemsWithAggregate({ collection: 'meeting', pipeline })

        parseImprovementData(items)
      } catch (error) {
        console.log(error);
        handleError({ error, defaultMessage: i18n.t('message.err_improvement_opportunities_list') })
      } finally {
        isLoading.value = false
      }
    }

    function parseImprovementData(items) {
      const [startFilter, endFilter] = getDatesFromRange(dateRangeFilter.value)

      excelFields.value = {
        [i18n.t(`improvement_opp.${inDemo ? 'dueDate' : 'date'}`)]: "date",
        [i18n.t('improvement_opp.created_by')]: "created_by",
        [i18n.t('improvement_opp.location')]: "location",
        [i18n.t('improvement_opp.domain')]: "domain.value",
        [i18n.t('improvement_opp.improvement')]: "note",
        [i18n.t('improvement_opp.state')]: "completedState.value",
      }

      let improvementData = []
      let creatorData = {}
      let locationData = {}
      let domainData = {}
      let wordCloudData = {}
      let improvementsWithDomain = 0
      let qCompleted = 0

      for (const meeting of items) {
        for (const improvement of meeting.improvements) {
          if (!improvement.deleted) {
            const date = inDemo && improvement.dueDate ? improvement.dueDate : dateFromObjectId(improvement._id)
            if (date >= startFilter && date < endFilter) {
              const creator = meeting.created_by?.name
              const creatorId = meeting.created_by?._id
              const location = meeting.location?.location
              const locationId = meeting.location?._id
              const domain = improvement.domain ? computed(() => i18n.t(`domain.${improvement.domain}`)) : ""
              const note = improvement.note
              let payload = {
                date: date.toLocaleDateString(`${default_language || 'en'}-US`),
                created_by: creator,
                location: location,
                domain: domain,
                note: note,
                completed: improvement.completed,
                completedState: setState(improvement.completed),
                id: improvement._id,
              }

              // Add metadata fields if applicable
              if (improvement.metadata) {
                improvement.metadata.forEach(e => {
                  payload[e.name] = e.answer
                  if (!excelFields.value.hasOwnProperty(i18n.t(`metadata.${e.name}`))) {
                    excelFields.value[i18n.t(`metadata.${e.name}`)] = e.name
                  }
                })
              }

              improvementData.push(payload)

              // Data for charts
              if (creator) {
                if (creatorData.hasOwnProperty(creator)) creatorData[creator].amount++
                else creatorData[creator] = {
                  amount: 1,
                  id: creatorId
                }
              }
              if (location) {
                if (locationData.hasOwnProperty(location)) locationData[location].amount++
                else locationData[location] = {
                  amount: 1,
                  id: locationId
                }
              }
              if (domain) {
                if (domainData.hasOwnProperty(domain.value)) domainData[domain.value]++
                else domainData[domain.value] = 1
                improvementsWithDomain++
              }
              if (improvement.completed) qCompleted++

              // Data for word cloud
              if (note) {
                let words = note.split(" ")
                words.forEach(e => {
                  if (e.length > 2) {
                    if (wordCloudData.hasOwnProperty(e)) wordCloudData[e]++
                    else wordCloudData[e] = 1
                  }
                });
              }
            }
          }
        }
      }
      improvements.value = improvementData
      improvementsTotal.value = improvementData.length
      improvementsCompleted.value = qCompleted
      improvementsPending.value = improvementsTotal.value - improvementsCompleted.value
      cloudWords.value = Object.entries(wordCloudData)
      excelFields.value[i18n.t('improvement_opp.id')] = "id"

      // Sort bar charts data
      let creatorDataSorted = Object.entries(creatorData)
      let locationDataSorted = Object.entries(locationData)

      creatorDataSorted.sort(function (a, b) {
        return b[1].amount - a[1].amount
      })

      locationDataSorted.sort(function (a, b) {
        return b[1].amount - a[1].amount
      })

      const dataLimit = 10 // Display only the first 10 values in the chart
      const creatorLimit = creatorDataSorted.length < dataLimit ? creatorDataSorted.length : dataLimit
      const locationLimit = locationDataSorted.length < dataLimit ? locationDataSorted.length : dataLimit
      
      let creatorLabels = []
      let creatorValues = []
      let creatorIds = []
      let locationLabels = []
      let locationValues = []
      let locationIds = []

      for (let i = 0; i < creatorLimit; i++) {
        creatorLabels.push(creatorDataSorted[i][0])
        creatorValues.push(creatorDataSorted[i][1].amount)
        creatorIds.push(creatorDataSorted[i][1].id)
      }

      for (let i = 0; i < locationLimit; i++) {
        locationLabels.push(locationDataSorted[i][0])
        locationValues.push(locationDataSorted[i][1].amount)
        locationIds.push(locationDataSorted[i][1].id)
      }

      creatorImprovements.value = {
        labels: creatorLabels,
        data: creatorValues,
        additionalInfo: {
          creatorIds: creatorIds,
        }
      };

      locationImprovements.value = {
        labels: locationLabels,
        data: locationValues,
        additionalInfo: {
          locationIds: locationIds,
        }
      };

      // Set donut chart data
      const domainLabels = Object.keys(domainData)
      const domainValues = Object.values(domainData)

      domainImprovements.value = {
        labels: domainLabels,
        data: domainValues,
        total: improvementsWithDomain
      };
    }

    const setState = (completed) => {
      return completed ? computed(() => i18n.t('completed')) : computed(() => i18n.t('pending'))
    };

    function updateFilter(data) {
      locationFilter.value = data.locationFilter;
      supervisorFilter.value = data.supervisorFilter;
      dateRangeFilter.value = data.dateRangeFilter;
      zoneFilter.value = data.zoneFilter;
      agencyFilter.value = data.agencyFilter;

      listImprovements();
    }

    const deleteImprovement = async (id) => {
      try {
        const query = { _id: ObjectId(id) }
        const action = { $set: { deleted: true } }

        await updateItem({ collection: 'improvement', query, action })

        listImprovements()
        showSuccessMessage(i18n.t('message.improvement_remove'))
      } catch (error) {
        console.log(error)
        showErrorMessage(i18n.t('message.improvement_remove_error'))
      }
    }

    const setWordCloudColors = ([, weight]) => {
      return weight > 3
        ? $themeColors.danger
        : weight > 2
          ? $themeColors.warning
          : weight > 1
            ? $themeColors.info
            : $themeColors.primary
    }

    const setWordCloudLoader = (progress) => {
      if (progress) {
        if (progress.completedWords === 0) isWordCloudLoading.value = true
        loadingWordCount.value = `${progress.completedWords}/${progress.totalWords}`
        if (progress.completedWords === progress.totalWords) isWordCloudLoading.value = false
      }
    }

    onMounted(() => {
      listImprovements()
    })

    return {
      improvements,
      improvementsTotal,
      improvementsCompleted,
      improvementsPending,
      creatorImprovements,
      locationImprovements,
      domainImprovements,
      updateFilter,
      deleteImprovement,
      excelFields,
      userRole,
      cloudWords,
      setWordCloudColors,
      setWordCloudLoader,
      isWordCloudLoading,
      loadingWordCount,
      isLoading,
      zone_agency_filter,
      colors,
      inDemo
    };
  },
};
</script>

<style lang="scss" scoped>
  .wordCloud {
    height: 400px;
    width: 800px;
    padding-top: 21px;
  }
  @media (max-width: 992px) {
      .wordCloud {
        width: 640px;
      }
  }
  @media (max-width: 768px) {
      .wordCloud {
        height: 300px;
        width: 400px;
      }
  }
  @media (max-width: 576px) {
      .wordCloud {
        height: 240px;
        width: 320px;
      }
  }
  @media (max-width: 400px) {
      .wordCloud {
        width: 240px;
      }
  }
</style>