<template>
  <b-card>
    <b-card-title class="mb-1">
      {{ $t('improvementsByDomain') }}
    </b-card-title>
    <!-- <b-card-sub-title class="mb-2">
      Spending on various categories
    </b-card-sub-title> -->

    <vue-apex-charts
      type="donut"
      height="350"
      :options="this.options"
      :series="this.series"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import useCommonDashboards from '@/views/habit/useCommonDashboards'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    domainData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    series() {
      return this.domainData.data
    },
    options() {
      const { colorPalette } = useCommonDashboards()
      const totalImprovements = this.domainData.total
      return {
        legend: {
          show: true,
          position: 'bottom',
          fontSize: '14px',
          fontFamily: 'Montserrat',
        },
        // colors: [
        //   '#ffe700',
        //   '#00d4bd',
        //   '#826bf8',
        //   '#2b9bf4',
        //   '#FFA1A1',
        // ],
        colors: colorPalette,
        dataLabels: {
          enabled: true,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  fontSize: '2rem',
                  fontFamily: 'Montserrat',
                },
                value: {
                  fontSize: '1.5rem',
                  fontFamily: 'Montserrat',
                  formatter(val) {
                    // eslint-disable-next-line radix
                    // return `${parseInt(val)}%`
                    return val
                  },
                },
                total: {
                  show: true,
                  fontSize: '1.5rem',
                  label: 'Total',
                  formatter() {
                    return totalImprovements
                  },
                },
              },
            },
          },
        },
        // labels: ['Operational', 'Networking', 'Hiring', 'R&D'],
        labels: this.domainData.labels,
        responsive: [
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 380,
              },
              legend: {
                position: 'bottom',
              },
            },
          },
          {
            breakpoint: 576,
            options: {
              chart: {
                height: 320,
              },
              plotOptions: {
                pie: {
                  donut: {
                    labels: {
                      show: true,
                      name: {
                        fontSize: '1.5rem',
                      },
                      value: {
                        fontSize: '1rem',
                      },
                      total: {
                        fontSize: '1.5rem',
                      },
                    },
                  },
                },
              },
              legend: {
                show: true,
              },
            },
          },
        ],
      }
    },
  },
}
</script>
