<template lang="pug">
.dashboard-wrapper
  dashboard-all-improvements(v-if="commitmentFunctionality")
  dashboard-default(v-else)
</template>

<script>
import DashboardAllImprovements from "./dashboards/DashboardAllImprovements.vue";
import DashboardDefault from "./dashboards/DashboardDefault.vue";

export default {
  components: {
    DashboardAllImprovements,
    DashboardDefault,
  },
  setup() {
    const commitmentFunctionality = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).commitment_functionality
      : false

    return {
      commitmentFunctionality,
    };
  },
};
</script>
